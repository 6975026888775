<template>
    <div>
        <div class="header pb-8 pt-2 pt-lg-6 d-flex align-items-center">

        </div>
        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col">
                    <div class="card shadow">
                        <div class="card-header border-0">
                            <div class="row align-items-center">
                                <div class="col"><h3 class="mb-0">Preklad blokov</h3></div>
                                <div class="col text-right">
                                    <a-button type="primary"  @click="addTerm = true">Pridať termín</a-button>
                                </div>
                            </div>
                        </div>
                        <div class="table-responsive" :key="componentKey" v-if="translations">

                            <div class="skipass-prices ant-table-body">
                                <table style="width:100%" >
                                    <thead class="ant-table-thead" >
                                    <tr>
                                        <th>jazyk originál</th><th >originál</th> <th>jazyk preklad</th><th >preklad</th><th></th>
                                    </tr>
                                    </thead>
                                    <tbody class="ant-table-tbody">
                                    <tr  :key="date + 'ski'" v-for="(datas,date) in translations">
                                        <td style="max-width:40px">{{datas.lang}}</td>
                                        <td style="font-size:12px;max-width:200px">{{datas.text}}</td>
                                        <td style="max-width:40px">{{datas.translang}}</td>
                                        <td style="font-size:12px;max-width:200px">{{datas.translation}}</td>
                                        <td><a-button class="" @click="openNew(datas)"><span class="btn-inner--icon" style="margin-right:5px"><i class="fas fa-pencil-alt"></i></span>Upraviť</a-button></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <a-modal title="Pridať termín" centered v-model="addTerm" @ok="handleOk" okText="Uložiť" cancelText="Zrušiť">
            <div class="additional-setup">
                <a-form-item
                        :label-col="formItemLayout.labelCol"
                        :wrapper-col="formItemLayout.wrapperCol"
                        label="Originálny jazyk"
                >  <a-select v-model="translation.lang" class="w-100" :options="langs"/>
                </a-form-item>
                <a-form-item
                        :label-col="formItemLayout.labelCol"
                        :wrapper-col="formItemLayout.wrapperCol"
                        label="Preklad do jazyka"
                >  <a-select defaultValue="1"  v-model="translation.translang" class="w-100" :options="langs"/>
                </a-form-item>
                <a-form-item
                        :label-col="formItemLayout.labelCol"
                        :wrapper-col="formItemLayout.wrapperCol"
                        label="Originál"
                > <a-input type="text" v-model="translation.text"></a-input>
                </a-form-item>
                <a-form-item
                        :label-col="formItemLayout.labelCol"
                        :wrapper-col="formItemLayout.wrapperCol"
                        label="Preklad"
                >  <a-input type="text" v-model="translation.translation"></a-input>
                </a-form-item>


            </div>
        </a-modal>



    </div>
</template>
<script>

    const formItemLayout = {
        labelCol: { span: 10 },
        wrapperCol: { span: 12 },
    };
    const formTailLayout = {
        labelCol: { span: 4 },
        wrapperCol: { span: 8, offset: 4 },
    };

    import {dataService} from "../_services";
    import {Popconfirm} from "ant-design-vue"

    import Vue from "vue"
    import FormItem from 'ant-design-vue'

    import moment from 'moment'
    import 'moment/locale/sk';

    Vue.use(FormItem);

    var locale = {
        "lang": {
            "placeholder": "Vybrať dátum",
            "rangePlaceholder": ["Termín od", "do"],
            "today": "Dnes",
            "now": "Teraz",
            "backToToday": "Späť na dnes",
            "ok": "Ok",
            "clear": "Vymazať",
            "month": "Mesiac",
            "year": "Rok",
            "timeSelect": "Vybrať čas",
            "dateSelect": "Vybrať dátum",
            "monthSelect": "Vybrať mesiac",
            "yearSelect": "Vybrať rok",
            "decadeSelect": "Vybrať dekádu",
            "yearFormat": "YYYY",
            "dateFormat": "M/D/YYYY",
            "dayFormat": "D",
            "dateTimeFormat": "M/D/YYYY HH:mm:ss",
            "monthFormat": "MMMM",
            "monthBeforeYear": true,
            "previousMonth": "Predchádzajúci mesiac (PageUp)",
            "nextMonth": "Nasledujúci mesiac (PageDown)",
            "previousYear": "Minulý rok (Control + left)",
            "nextYear": "Nasledujúci rok (Control + right)",
            "previousDecade": "Minulá dekáda",
            "nextDecade": "Nasledujúca dekáda",
            "previousCentury": "Minulé storočie",
            "nextCentury": "Nasledujúce storoťie"
        },
        "timePickerLocale": {
            "placeholder": "Vybrať čas"
        },
        "dateFormat": "DD.MM.YYYY",
        "dateTimeFormat": "DD.MM.YYYY HH:mm:ss",
        "weekFormat": "YYYY-wo",
        "monthFormat": "YYYY-MM"
    }

    export default {
        name: "skipass",
        props: ["id"],
        components: {
            "a-popconfirm": Popconfirm
        },
        data() {
            return {
                data: [],
                ctt: [],
                langs:[],
                colorsto: JSON.parse(localStorage.colors),
                loading: false,
                filteredInfo: null,
                componentKey:0,
                original:'',
                translation:{},
                inputLang: null,
                translations:[],
                addLimit: false,
                rdateFormat: 'YYYY/MM/DD',
                formItemLayout,
                formTailLayout,
                skipass: {prices:{}},
                locale,
                types: [
                    {"value": '0', "label": 'Dospelý'},
                    {"value": '1', "label": 'Junior / senior'},
                    {"value": '2', "label": 'Dieťa'},
                ],
                addTerm: false,
                sortedInfo: null,
            };
        },
        computed: {
            columns() {
                let { sortedInfo, filteredInfo } = this;
                sortedInfo = sortedInfo || {};
                filteredInfo = filteredInfo || {};
                const columns = [
                    {
                        title: 'Popis bloku',
                        dataIndex: 'des',
                        sorter: true,
                        width: '20%',
                    },
                    {
                        title: 'Jazyk',
                        scopedSlots: {customRender: 'language'},
                        filters: this.language,
                        onFilter: (value, record) => record.language.includes(value),
                        dataIndex: 'language',
                    },
                    {
                        title: 'Typ obsahu',
                        scopedSlots: {customRender: 'contenttype'},
                        filters: this.ctt,
                        onFilter: (value, record) => record.content_type.includes(value),
                        dataIndex: 'showon',
                    },

                    {
                        align: 'right',
                        scopedSlots: {customRender: 'name'},
                    }
                ];
                return columns;     },
        },
        mounted() {
            this.fetch();


            dataService.axiosFetch("contenttypes").then(results => {
                this.contenttypes = results;

                results.forEach(obj => {
                    var iid = obj.id;
                    var vval = obj.title;
                    this.ctt.push({"text" : vval,"value" : iid});


                });
            });

            dataService.axiosFetch('languages').then(results => {
                results.forEach(obj => {
                    if(obj.def === "1") this.translation.lang = obj.short; else this.translation.translang = obj.short;  this.langs.push({"value" : obj.short , "label" : obj.title});
                });
            });
        },
        methods: {
            openNew: function(e) {
                this.addTerm = true;

                this.translation = e;

            },
            moment,
            formatDateN: function(dt){
                return moment(dt,'YYYY-MM-DD').format('D.M.YYYY')
            },
            confirm(e) {
                dataService.axiosDelete('skipass', e).then(results => {
                    if (results) {
                        delete this.data[e];
                        this.componentKey = this.componentKey + 1;
                    }
                });
            },
            handleLimit()
            {
                dataService.axiosPost('addskipasslimit', { 'skipass' : this.skipass}).then(results => {
                    this.fetch();
                    this.addLimit = false;
                });
            },
            handleOk(){

                dataService.axiosPost('addlocaltranslation', this.translation).then(results => {
              this.fetch();
                    this.addTerm = false;
                });
            },
            cancel() {
            },
            handleChange(pagination, filters, sorter) {
                this.filteredInfo = filters;
                this.sortedInfo = sorter;
            },
            clearFilters() {
                this.filteredInfo = null;
            },
            editing(w)
            {

                this.skipass.prices = this.data[w];
                this.skipass.daterange = [moment(w,this.rdateFormat),moment(w,this.rdateFormat)];

                this.addTerm = true;
            },
            fetchcttitle(ct)
            {
                let tcc = this.contenttypes.find(x => x.slug === ct);
                return tcc.title;
            },
            fetch() {
                this.loading = true;
                dataService.axiosFetch("localtranslations").then(results => {
                this.translations = results;
                    this.loading = false;
                });


            }

        },
    };
</script>

<style>
    .prices-row label
    {width:100px;}
    .prices-row
    {display:inline-block;}
    .priceInput
    {border:1px solid #bdd1e4;}
    .price-input
    {text-align:center;
    }
    .skipass-prices td{
        min-width:150px;
    }
    .skipass-prices tbody th{
        min-width:250px;
        font-weight:100;
    }
    .price-input strong
    {margin-bottom:5px;display:block;}
    .price-input
    {
        margin-left:10px; width:50px;display:inline-block;
    }
    ul.ant-pagination {
        margin-top: 0;
        margin-bottom: 1em;
    }
    .ant-table-pagination.ant-pagination {
        margin-bottom: 40px;
        margin-right: 20px;
    }

    .ant-pagination-item {
        border: 0;
    }

    .ant-table {
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        margin-bottom: 20px;
    }

    .ant-pagination-item a, .ant-pagination-prev a, .ant-pagination-next a, .ant-pagination-jump-prev a, .ant-pagination-jump-next a {
        -webkit-box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
        box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
        border: 0;
    }

    .ant-pagination-item a, .ant-pagination-prev a, .ant-pagination-next a {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding: 0;
        margin: 0 3px;
        border-radius: 50% !important;
        width: 36px;
        height: 36px;
        font-size: 0.875rem;
    }


    .ant-pagination-item.ant-pagination-item-active a {
        z-index: 1;
        color: #fff;
        background-color: #5e72e4;
        border-color: #5e72e4;
    }

    .ant-pagination-item-link.ant-pagination-disabled a {
        color: #8898aa;
        pointer-events: none;
        cursor: auto;
        background-color: #fff;
        border-color: #dee2e6;
    }

</style>
